import { Permission } from "../models/Permission";
import { Menu } from "../models/Menu";

export const hasRoutePermissions = (
  permissions: string | string[],
  currentUser: any
) => {
  if (currentUser.id === 0) {
    return false;
  }

  const currentUrlPermissions: Menu[] = currentUser.allowedMenus || [];

  if (typeof permissions === "string") {
    permissions = [permissions];
  }

  if (permissions.length === 0) {
    return true;
  } else {
    let check = 0;

    for (let permission of permissions) {
      permission = permission.trim().toUpperCase();

      if (
        currentUrlPermissions.find((currentPermission: Menu) => {
          return currentPermission.code === permission;
        })
      ) {
        check++;
      }
    }

    return check === permissions.length;
  }
};
