import './TenantButton.scss';

interface TenantButtonInterface {
  className?: string;
  disabled?: boolean;
  text: string | React.ReactNode;
  fullwidth?: boolean;
  action: () => void;
}

const TenantButton = ({
  className = '',
  disabled = false,
  text,
  fullwidth = false,
  action,
}: TenantButtonInterface) => {
  return (
    <button
      className={`tenant__btn${className ? ` ${className}` : ''}${
        disabled ? ' tenant__btn_disabled' : ''
      }${fullwidth ? ' tenant__btn_w100' : ''}`}
      onClick={() => {
        action();
      }}
    >
      {text}
    </button>
  );
};

export default TenantButton;
