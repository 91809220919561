import React from 'react';

function TenantNotAvailable() {
  const hostname = window.location.hostname;
  return (
    <div>
      Aplicación no disponible, debido a que el hostname <b>{hostname}</b> no se
      encuentra configurado.
    </div>
  );
}

export default TenantNotAvailable;
