import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { useModal } from "react-simple-hook-modal";
import { FunctionsHelper } from "../../utils/FunctionsHelper";
import book from "../../assets/images/icon-book.svg";
import plus from "../../assets/images/fi-rr-plus-small.svg";
import exit from "../../assets/images/fi-rr-sign-out.svg";
import { Tenant } from "../../models/Tenant";
import { User } from "../../models/User";
import TenantButton from "../../components/TenantButton/TenantButton";
import { Link, useNavigate } from "react-router-dom";
import AuthAction from "../../redux/actions/authActions/Actions";
import { AuthService } from "../../services/AuthService";
import { MenuType } from "../../config/Config";

import "./styles.scss";

const Header = (props: any) => {
  const [isOpen, setIsOpen] = useState(false);
  const user: User = useSelector((state: any) => state.auth);
  const tenant: Tenant = useSelector((state: any) => state.tenant);
  const menus = user.allowedMenus;
  const history = useNavigate();
  // const history = useHistory();
  const dispatch = useDispatch();
  const { isModalOpen, openModal, closeModal } = useModal();

  // const Logout = async () => {
  //   await AuthService.logout();
  //   useDispatch(AuthAction.signOut({}));
  // };
  const location = useLocation();

  const isMenuActive = (route: string): string => {
    console.log(route);
    return location.pathname.startsWith(route) ? " active" : "";
  };
  const logout = async () => {
    await AuthService.logout();
    dispatch(AuthAction.signOut({}));
  };

  return (
    <div className="MainHeader">
      <div className="Header">
        <div className="Header__logo">
          {tenant.tenantAppLogo && (
            <img
              src={`${FunctionsHelper.getS3FileUrl(tenant.tenantAppLogo)}`}
              alt=""
              className="only-desktop header__logo__style"
            />
          )}
        </div>
        <div className="Header__Desktop">
          <span
            onClick={() => {
              console.log("nothing"); /*history.push('/profile')*/
            }}
          >
            {user.name} {user.lastname}
          </span>
        </div>
      </div>
      <div className="HeaderSide">
        <div className={`sidebar`}>
          <div className="sidebar__menu">
            <div onClick={() => {}} className="sidebar__logo">
              PORTAL DEL CLIENTE
            </div>
            <ul className="sidebar-menu">
              {menus.map((menu) => (
                <>
                  {menu.menuTypeId === MenuType.STANDARD_MENU ? (
                    <li
                      className={`sidebar__option${isMenuActive(
                        menu.redirectLink || ""
                      )}`}
                      key={menu.code}
                    >
                      <Link to={menu.redirectLink || "/"} key={menu.code}>
                        <img src={menu.icon?.url || book} alt="icono" />
                        <span>{menu.title}</span>
                      </Link>
                    </li>
                  ) : menu.menuTypeId === MenuType.IFRAME_VIEWER ? (
                    <li
                      className={`sidebar__option${isMenuActive(
                        "/dynamic-viewer/" + menu.code || ""
                      )}`}
                      key={menu.code}
                    >
                      <Link
                        to={`/dynamic-viewer/${menu.code}` || "/"}
                        key={menu.code}
                      >
                        <img src={menu.icon?.url || book} alt="icono" />
                        <span>{menu.title}</span>
                      </Link>
                    </li>
                  ) : menu.menuTypeId === MenuType.URL_REDIRECT ? (
                    <li
                      className={`sidebar__option${isMenuActive(
                        menu.redirectLink || ""
                      )}`}
                      key={menu.code}
                    >
                      <a
                        target={menu.additionalConfig?.target || undefined}
                        href={menu.redirectLink || undefined}
                      >
                        <img src={menu.icon?.url || book} alt="icono" />
                        <span>{menu.title}</span>
                      </a>
                    </li>
                  ) : (
                    menu.menuTypeId === MenuType.SUBTITLE && (
                      <div className="sidebar__titlem" key={menu.code}>
                        {menu.title}
                      </div>
                    )
                  )}
                </>
              ))}
            </ul>
            <div className="auth__main__content__button">
              {menus.map((menu) => (
                <>
                  {menu.menuTypeId === MenuType.BUTTON_REDIRECT ? (
                    <TenantButton
                      text={
                        <>
                          <img src={menu.icon?.url || plus} alt="icono" />
                          <span>{menu.additionalConfig?.title}</span>
                        </>
                      }
                      action={() => history(`${menu.redirectLink}`)}
                      fullwidth
                      key={menu.code}
                    />
                  ) : null}
                </>
              ))}
            </div>
            <li
              onClick={() => {
                logout();
              }}
              className="sidebar__option top"
            >
              <img src={exit} alt="" />
              <span>Cerrar sesión</span>
            </li>
          </div>
        </div>
        {props.children}
      </div>
    </div>
  );
};

export default Header;
