import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { User } from "../../models/User";
import { useSelector } from "react-redux";
import Header from "../Header/Header";
import { MenuType } from "../../config/Config";

import "./DynamicViewer.scss";

function DynamicViewer() {
  const { key }: any = useParams();
  const user: User = useSelector((state: any) => state.auth);
  const [src, setSrc] = useState("");
  const [title, setTitle] = useState("");
  /* Settings */
  const heightOffset = 73;
  const vh = window.innerHeight;
  const vw = window.innerWidth;

  useEffect(() => {
    const availableData = user.allowedMenus.find((menu) => {
      return menu.code === key && menu.menuTypeId === MenuType.IFRAME_VIEWER;
    });

    if (availableData) {
      const url = availableData.redirectLink || "";
      const prefixUrl = availableData?.additionalConfig?.prefixUrl || "";
      setSrc(`${prefixUrl}${url}`);
      setTitle(availableData.title);
    } else {
      setSrc("");
      setTitle("");
    }
  }, [key]);

  return (
    <Header>
      {src && (
        <iframe
          src={src}
          title={title}
          frameBorder={0}
          style={{
            marginTop: `${heightOffset}px`,
            width: `${vw}px`,
            height: `${vh - heightOffset}px`,
          }}
        />
      )}
    </Header>
  );
}

export default DynamicViewer;
