// PhoneInput.tsx
import React ,{ useState } from 'react';

import './TenantInput.scss';

interface TenantInputInterface {
  label?: string;
  value: string;
  setValue: (newValue: string) => void;
  disabled?: boolean;
  placeholder?: string;
  fullwidth?: boolean;
  errorMessage?: string;
  loading?: boolean;
  maxLength?: number;
  onEnterAction?: () => void;
}

const TenantInput = ({
  label = '',
  value,
  setValue,
  disabled = false,
  placeholder = '',
  fullwidth = false,
  errorMessage = '',
  maxLength,
  onEnterAction,
}: TenantInputInterface) => {
  const [complete, setComplete] = useState(false);
  const handleKeyPress = (e: any) => {
    if (e.key === 'Enter' && onEnterAction) {
      onEnterAction();
    }
  };
  const handleChange = (e:any) => {

    const inputValue = e.target.value;
    if(inputValue){
        setComplete(true);
    }else{
        setComplete(false);
    }
    setValue(inputValue);
  };

  return (
    <div
      className={`tenant__input_container${
        fullwidth ? ' tenant__input_container_w100' : ''
      }`}
    >
      {label.trim() && <div className="tenant__input_label">{label}</div>}
      <input
        type="text"
        className={`tenant__text_input${
          errorMessage.trim() ? ' tenant__input__border__error__color' : ''
        }${fullwidth ? ' tenant__input_container_w100' : ''} ${complete? ' tenant_input_complete':''}`}
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        disabled={disabled}
        maxLength={maxLength}
        autoComplete="off"
        onKeyPress={handleKeyPress}
      />
      {errorMessage.trim() && (
        <div className="tenant__error_message">{errorMessage.trim()}</div>
      )}
    </div>
  );
};

export default TenantInput;
