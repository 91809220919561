import { FETCH, SAVE, UPDATE, REMOVE } from './ActionTypes';

export function save(payload: any) {
  return {
    type: SAVE,
    payload,
  };
}

export function fetch(payload: any) {
  return {
    type: FETCH,
    payload,
  };
}

export function update(payload?: any) {
  return {
    type: UPDATE,
    payload,
  };
}

export function remove(payload?: any) {
  return {
    type: REMOVE,
    payload,
  };
}

const TenantAction = {
  save,
  fetch,
  update,
  remove,
};

export default TenantAction;
