import { MAIN_BACKEND_URL } from '../config/Config';
import { ApiErrorData, ApiResponse } from '../models/ApiResponse';
import axios, { AxiosError } from 'axios';

export const FunctionsHelper = {
  getS3FileUrl: (s3RawKey: string) => {
    return `${MAIN_BACKEND_URL}/file/download/${encodeURIComponent(s3RawKey)}`;
  },
  getErrorFormat: (error: any): ApiResponse<ApiErrorData> => {
    console.error(error);

    let status = 500;
    let errorCode = 'GENERIC_ERROR';
    let errorMessage = 'Error de red, no se pudo conectar al servidor.';
    let payload = undefined;

    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;
      if (axiosError.response) {
        const dataError = axiosError.response?.data
          ? axiosError.response?.data
            ? (axiosError.response?.data as any).data
            : undefined
          : undefined;

        if (dataError) {
          //Puede ser un string o un objeto con este formato: { code: string, message: string, payload?: number | string };
          if (typeof dataError === 'string') {
            errorCode = 'VALIDATION_OR_OTHER_ERROR';
            errorMessage = dataError;
          } else {
            // Si dataError es un objeto, extraemos sus propiedades para asignar el código, mensaje y carga útiles
            errorCode = dataError.code || 'UNKNOWN_CODE_ERROR';
            errorMessage =
              dataError.message || 'Ocurrió un error en el servidor';
            payload = dataError.payload || payload;
          }
        } else {
          errorCode = 'API_BAD_FORMAT_ERROR';
          errorMessage =
            'Error, obtuvo una respuesta sin el formato correcto desde servidor.';
        }

        // Error de respuesta del servidor (4xx o 5xx)
        console.log(
          `Error: ${axiosError.response.status} - ${axiosError.response.statusText}`,
          dataError
        );
      } else if (axiosError.request) {
        // Error de solicitud (sin respuesta del servidor)
        errorCode = 'SERVER_NO_RESPONSE_ERROR';
        errorMessage =
          'Error de solicitud, no se recibió respuesta del servidor.';
      } else {
        // Otros errores
        errorCode = 'APPLICATION_PROCESS_ERROR';
        errorMessage = 'Error al procesar la solicitud.';
      }
    }

    return {
      success: false,
      status,
      data: {
        code: errorCode,
        message: errorMessage,
        payload,
      },
    };
  },
};
