import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AuthAction from '../../redux/actions/authActions/Actions';
import { AuthService } from '../../services/AuthService';
import { Tenant } from '../../models/Tenant';
import { ApiErrorData } from '../../models/ApiResponse';

import './Login.scss';
import { FunctionsHelper } from '../../utils/FunctionsHelper';
import TenantInput from '../../components/TenantInput/TenantInput';
import TenantPasswordInput from '../../components/TenantPasswordInput/TenantPasswordInput';
import TenantButton from '../../components/TenantButton/TenantButton';
import { Link } from 'react-router-dom';

interface LocalFormError {
  username: string;
  password: string;
}

const initFormError: LocalFormError = {
  username: '',
  password: '',
};

function Login() {
  const dispatch = useDispatch();
  const tenant: Tenant = useSelector((state: any) => state.tenant);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState<LocalFormError>(initFormError);
  const [loading, setLoading] = useState(false);
  const [disabledBotton, setDisabledBotton] = useState(false);

  const hasContent = (data: LocalFormError): boolean => {
    for (const key in data) {
      if (data[key as keyof LocalFormError]) {
        return true;
      }
    }
    return false;
  };
  const evaluateError = (): LocalFormError => {
    const localErrors = { ...initFormError };

    if (!username) {
      localErrors.username = 'El campo es requerido.';
    }

    if (!password) {
      localErrors.password = 'El campo es requerido.';
    }

    setErrors(localErrors);
    return localErrors;
  };

  const submit = async () => {
    const localErrors = evaluateError();
    if(!hasContent(localErrors) && !loading){
      setLoading(true);
      setDisabledBotton(true);
      const loginResponse = await AuthService.login({
        username,
        password,
      });
      if (loginResponse.success) {
        dispatch(AuthAction.signIn(loginResponse.data));
      } else {
        const error = loginResponse.data as ApiErrorData;
        alert(error.message);
        setLoading(false);
        setDisabledBotton(false);
      }
    }
  };

  return (
    <div className="sign_in_container">
      {tenant.tenantAppLogo && (
        <div className="sign_in_image">
          <img
            src={`${FunctionsHelper.getS3FileUrl(tenant.tenantAppLogo)}`}
            alt="logo"
            style={{
              width: '100px',
            }}
          />
        </div>
      )}
      <div className="sign_in_form_container">
        <h1 className="sign_in_title">¡Te estábamos esperando!</h1>
        <p className="sign_in_subtitle">
          Ingrese su nombre de usuario y contraseña para ingresar a nuestro
          sistema.
        </p>
        <div className="group-inputs">
          <TenantInput
            label="Usuario"
            value={username}
            setValue={setUsername}
            placeholder="Ingresar el usuario"
            errorMessage={errors.username}
            disabled={loading}
          />
          <TenantPasswordInput
            label="Contraseña"
            value={password}
            setValue={setPassword}
            placeholder="Ingresar la contraseña"
            errorMessage={errors.password}
            disabled={loading}
            onEnterAction={submit}
          />
        </div>
        <div style={{ marginTop: '30px' }}>
          <TenantButton
            text={loading ? 'Cargando...' : 'Ingresar'}
            fullwidth
            action={submit}
            disabled={disabledBotton}
          />
          <div className="sign_in_footer_cross">
            <Link to={'/forgotten-password'} className="forgotten">¿Olvidaste la contraseña?</Link>
            <div className="sign_in_footer__sub_cross">
              <span>¿No tienes cuenta?</span>
              <Link to={'/sign-up'} className="sign">
                Regístrate <b>aquí</b>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>

    // <div
    //   style={{
    //     display: 'flex',
    //     width: '100%',
    //     justifyContent: 'center',
    //     gap: '20px',
    //   }}
    // >

    //   <TenantForm title='¡Te estábamos esperando!' subTitle='Ingrese su nombre de usuario y contraseña para ingresar a nuestrosistema.'>
    //     <Input label='Usuario' placeholder='Ingrese el usuario' ></Input>
    //     <TenantPasswordInput label='Password' placeholder='Ingrese la contraseña'></TenantPasswordInput>
    //     <TenantButton text='Ingresar' action={login} backgroundColor='#707070' color='#FFFFFF'></TenantButton>
    //     <Footer title='¿Olvidaste la contraseña?' subTitleQuestion='¿No tienes cuenta?' subTitle='Regístrate '></Footer>
    //   </TenantForm>
    //   {/* <div
    //     style={{
    //       width: '400px',
    //       display: 'flex',
    //       gap: '20px',
    //       flexDirection: 'column',
    //     }}
    //   >
    //     <h1
    //       style={{
    //         color: 'gray',
    //         fontWeight: '700',
    //         textAlign: 'center',
    //         textTransform: 'uppercase',
    //         fontSize: '20px',
    //       }}
    //     >
    //       Inquilino: {tenant.name}
    //     </h1>

    //     {tenant.tenantAppLogo && (
    //       <div
    //         style={{
    //           display: 'flex',
    //           justifyContent: 'center',
    //         }}
    //       >
    //         <img
    //           src={`${FunctionsHelper.getS3FileUrl(tenant.tenantAppLogo)}`}
    //           alt="logo"
    //           style={{
    //             width: '100px',
    //           }}
    //         />
    //       </div>
    //     )}

    //     <input
    //       type="text"
    //       placeholder="Usuario"
    //       value={username}
    //       onChange={(event) => {
    //         setUsername(event.target.value);
    //       }}
    //       autoComplete="off"
    //       style={{ padding: '8px 8px', borderRadius: '3em' }}
    //     />
    //     <input
    //       type="password"
    //       placeholder="Contraseña"
    //       value={password}
    //       onChange={(event) => {
    //         setPassword(event.target.value);
    //       }}
    //       autoComplete="off"
    //       style={{ padding: '8px 8px', borderRadius: '3em' }}
    //     />
    //     <Button
    //       label="Iniciar sesión"
    //       primary
    //       onClick={() => {
    //         login();
    //       }}
    //     />
    //   </div> */}
    // </div>
  );
}

export default Login;
