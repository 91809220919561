import { Tenant } from '../models/Tenant';
import { FunctionsHelper } from '../utils/FunctionsHelper';

export const loadTenantConfig = (tenant: Tenant) => {
  /* Favicon */
  const favicon: any =
    document.querySelector("link[rel='icon']") ||
    document.createElement('link');
  favicon.type = 'image/x-icon';
  favicon.rel = 'icon';

  if (tenant.tenantAppFavicon) {
    favicon.href = FunctionsHelper.getS3FileUrl(tenant.tenantAppFavicon);
    document.head.appendChild(favicon);
  } else {
    document.head.removeChild(favicon);
  }

  /* Title */
  document.title = tenant.tenantAppTitle;

  /* Styles css */
  for (const propertyDataRow of tenant.publicCssProperties) {
    document.documentElement.style.setProperty(
      `--${propertyDataRow.key}`,
      propertyDataRow.value
    );
  }

  for (const propertyDataRow of tenant.privateCssProperties) {
    document.documentElement.style.setProperty(
      `--${propertyDataRow.key}`,
      propertyDataRow.value
    );
  }
};
