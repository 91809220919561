import { Tenant } from "../../models/Tenant";
import { Action } from "../models/Action";
import {
  FETCH,
  REMOVE,
  SAVE,
  UPDATE,
} from "../actions/tenantActions/ActionTypes";

const defaultStore: Tenant = {
  id: 0,
  name: "",
  domain: "",
  countryCode: "",
  currencyCode: "",
  defaultDialCode: "",
  mainTimezone: "",
  tenantAppTitle: "",
  tenantAppLogo: null,
  tenantAppFavicon: null,
  currency: {
    code: "",
    name: "",
    symbol: "",
  },
  country: {
    code: "",
    name: "",
    alpha3Code: "",
    dialCode: "",
    mainTimezone: "",
  },
  publicCssProperties: [],
  privateCssProperties: [],
  availableMenus: [],
};

export default function TenanReducer(
  state: Tenant = defaultStore,
  action: Action
) {
  switch (action.type) {
    case SAVE:
      return {
        ...action.payload,
      };

    case FETCH:
      return {
        ...state,
      };

    case UPDATE:
      return {
        ...state,
        ...action.payload,
      };

    case REMOVE:
      return {
        ...state,
        ...defaultStore,
      };

    default:
      return state;
  }
}
