// PhoneInput.tsx
import React, { useState } from 'react';

import './TenantPasswordInput.scss';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

interface TenantPasswordInputInterface {
  label?: string;
  value: string;
  setValue: (newValue: string) => void;
  disabled?: boolean;
  placeholder?: string;
  fullwidth?: boolean;
  errorMessage?: string;
  loading?: boolean;
  maxLength?: number;
  onEnterAction?: () => void;
}

const TenantPasswordInput = ({
  label = '',
  value,
  setValue,
  disabled = false,
  placeholder = '',
  fullwidth = false,
  errorMessage = '',
  maxLength,
  onEnterAction,
}: TenantPasswordInputInterface) => {
  const [showPassword, setShowPassword] = useState(false);
  const [complete, setComplete] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleKeyPress = (e: any) => {
    if (e.key === 'Enter' && onEnterAction) {
      onEnterAction();
    }
  };
  const handleChange = (e:any) => {

    const inputValue = e.target.value;
    if(inputValue){
        setComplete(true);
        errorMessage='';
    }else{
        setComplete(false);
    }
    setValue(inputValue);
  };

  return (
    <div
      className={`tenant__input_container${
        fullwidth ? ' tenant__input_container_w100' : ''
      }`}
    >
      {label.trim() && <div className="tenant__input_label">{label}</div>}
      <div
        className={`tenant__text_input_password_container${
          fullwidth ? ' tenant__input_container_w100_password' : ''
        }`}
      >
        <input
          type={showPassword ? 'text' : 'password'}
          className={`tenant__text_input_password${
            errorMessage.trim()
              ? ' tenant__input__border__error__color_pasword'
              : ''
          }${fullwidth ? ' tenant__input_container_w100_password' : ''} ${complete? ' tenant_input_complete':''}`}
          placeholder={placeholder}
          value={value}
          onChange={handleChange}
          disabled={disabled}
          maxLength={maxLength}
          autoComplete="new-password"
          onKeyPress={handleKeyPress}
        />
        <button
          type="button"
          className="tenant__password_toggle_button"
          onClick={togglePasswordVisibility}
        >
          {showPassword ? <VisibilityOffIcon /> : <RemoveRedEyeIcon />}
        </button>
      </div>

      {errorMessage.trim() && (
        <div className="tenant__error_message">{errorMessage.trim()}</div>
      )}
    </div>
  );
};

export default TenantPasswordInput;
