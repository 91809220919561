import MainBackendAxios from "../utils/MainBackendAxios";
import { User } from "../models/User";
import { LoginDto } from "../models/dtos/LoginDto";
import { ApiErrorData, ApiResponse } from "../models/ApiResponse";
import { FunctionsHelper } from "../utils/FunctionsHelper";

export const AuthService = {
  login: async (
    loginParams: LoginDto
  ): Promise<ApiResponse<User | ApiErrorData>> => {
    try {
      const response = await MainBackendAxios.post(`/auth/login`, loginParams);
      const userData = response?.data?.data;
      return {
        success: true,
        status: response.status,
        data: {
          id: userData.id,
          username: userData.username,
          userType: userData.userType,
          name: userData.name,
          lastname: userData.lastname,
          email: userData.email,
          dialCode: userData.dialCode,
          phone: userData.phone || null,
          avatar: userData.avatar || null,
          createdAt: userData.createdAt,
          activatedAt: userData.activatedAt || null,
          disabledAt: userData.disabledAt || null,
          token: userData.sessionToken,
          permissions: userData.permissions
            ? userData.permissions.map((permission: any) => {
                return {
                  code: permission.code,
                  name: permission.name,
                  description: permission.description,
                  createdAt: permission.createdAt,
                };
              })
            : [],
          roles: userData.roles
            ? userData.roles.map((role: any) => {
                return {
                  id: role.id,
                  name: role.name,
                  description: role.description,
                  createdAt: role.createdAt,
                };
              })
            : [],
          allowedMenus: userData.recruiterCrossAppMenus
            ? userData.recruiterCrossAppMenus.map(
                (recruiterCrossAppMenu: any) => {
                  return {
                    code: recruiterCrossAppMenu.code,
                    title: recruiterCrossAppMenu.title,
                    menuTypeId: recruiterCrossAppMenu.menuTypeId,
                    redirectLink: recruiterCrossAppMenu.redirectLink,
                    additionalConfig: recruiterCrossAppMenu.additionalConfig,
                    columnOrder: recruiterCrossAppMenu.columnOrder,
                    icon: recruiterCrossAppMenu.menuIcon
                      ? {
                          id: recruiterCrossAppMenu.menuIcon.id,
                          name: recruiterCrossAppMenu.menuIcon.name,
                          url: recruiterCrossAppMenu.menuIcon.url,
                        }
                      : null,
                  };
                }
              )
            : [],
        },
      };
    } catch (err) {
      return FunctionsHelper.getErrorFormat(err);
    }
  },
  verifySession: async (): Promise<ApiResponse<User | ApiErrorData>> => {
    try {
      const response = await MainBackendAxios.post(`/auth/get-current-user`);

      const userData = response.data.data;

      return {
        success: true,
        status: response.status,
        data: {
          id: userData.id,
          username: userData.username,
          userType: userData.userType,
          name: userData.name,
          lastname: userData.lastname,
          email: userData.email,
          dialCode: userData.dialCode,
          phone: userData.phone || null,
          avatar: userData.avatar || null,
          createdAt: userData.createdAt,
          activatedAt: userData.activatedAt || null,
          disabledAt: userData.disabledAt || null,
          token: userData.sessionToken,
          permissions: userData.permissions
            ? userData.permissions.map((permission: any) => {
                return {
                  code: permission.code,
                  name: permission.name,
                  description: permission.description,
                  createdAt: permission.createdAt,
                };
              })
            : [],
          roles: userData.roles
            ? userData.roles.map((role: any) => {
                return {
                  id: role.id,
                  name: role.name,
                  description: role.description,
                  createdAt: role.createdAt,
                };
              })
            : [],
          allowedMenus: userData.recruiterCrossAppMenus
            ? userData.recruiterCrossAppMenus.map(
                (recruiterCrossAppMenu: any) => {
                  return {
                    code: recruiterCrossAppMenu.code,
                    title: recruiterCrossAppMenu.title,
                    menuTypeId: recruiterCrossAppMenu.menuTypeId,
                    redirectLink: recruiterCrossAppMenu.redirectLink,
                    additionalConfig: recruiterCrossAppMenu.additionalConfig,
                    columnOrder: recruiterCrossAppMenu.columnOrder,
                    icon: recruiterCrossAppMenu.menuIcon
                      ? {
                          id: recruiterCrossAppMenu.menuIcon.id,
                          name: recruiterCrossAppMenu.menuIcon.name,
                          url: recruiterCrossAppMenu.menuIcon.url,
                        }
                      : null,
                  };
                }
              )
            : [],
        } as User,
      };
    } catch (err: any) {
      return FunctionsHelper.getErrorFormat(err);
    }
  },
  logout: async (): Promise<ApiResponse<ApiErrorData>> => {
    try {
      const response = await MainBackendAxios.post("/auth/logout");
      console.log("response logout", response);
      return {
        success: true,
        status: response.status,
      };
    } catch (err) {
      return FunctionsHelper.getErrorFormat(err);
    }
  },
};
