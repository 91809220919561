import React from "react";
import { Navigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Unauthorized from "../../scenes/Unauthorized/Unauthorized";
import { hasRoutePermissions } from "../../utils/AuthUtils";
import { User } from "../../models/User";
import { Tenant } from "../../models/Tenant";
import NotFound from "../../scenes/NotFound/NotFound";
import { MenuType } from "../../config/Config";

type PrivateRouteProps = {
  outlet: JSX.Element;
  permissionsRequired?: string | string[];
  type?: MenuType;
};

function PrivateRoute({
  outlet,
  permissionsRequired = [],
  type = MenuType.STANDARD_MENU,
}: PrivateRouteProps) {
  const user: User = useSelector((state: any) => state.auth);
  const tenant: Tenant = useSelector((state: any) => state.tenant);
  const params = useParams();

  if (user?.id !== 0 && hasRoutePermissions(permissionsRequired, user)) {
    if (type && MenuType.IFRAME_VIEWER === type) {
      const { key } = params;

      const availableMenuData = user.allowedMenus.find((menu) => {
        return menu.code === key && menu.menuTypeId === type;
      });

      const menuData = tenant.availableMenus.find((menu) => {
        return menu.code === key && menu.menuTypeId === type;
      });

      if (!menuData) {
        return <NotFound />;
      }

      if (!availableMenuData) {
        return <Unauthorized />;
      }
    }

    return outlet;
  } else if (
    user?.id !== 0 &&
    !hasRoutePermissions(permissionsRequired, user)
  ) {
    return <Unauthorized />;
  }

  return <Navigate to="/login" />;
}

export default PrivateRoute;
