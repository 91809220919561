import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { MenuType } from "../../config/Config";
import { User } from "../../models/User";

type PrivateRouteProps = {
  outlet: JSX.Element;
};

function GuestRoute({ outlet }: PrivateRouteProps) {
  const user: User = useSelector((state: any) => state.auth);
  let defaultRoute = "/home";

  if (user?.id === 0) {
    return outlet;
  }

  const menus = user.allowedMenus.filter((menu) => {
    return [
      MenuType.STANDARD_MENU as string,
      MenuType.IFRAME_VIEWER as string,
    ].includes(menu.menuTypeId);
  });

  if (menus.length > 0) {
    const firstMenu = menus[0];
    if (firstMenu.redirectLink) {
      if (firstMenu.menuTypeId === MenuType.STANDARD_MENU) {
        defaultRoute = firstMenu.redirectLink;
      }
      if (firstMenu.menuTypeId === MenuType.IFRAME_VIEWER) {
        defaultRoute = `/dynamic-viewer/${firstMenu.code}`;
      }
    }
  }

  return <Navigate to={defaultRoute} />;
}

export default GuestRoute;
