import React from 'react';
import { useSelector } from 'react-redux';
import { Tenant } from '../../models/Tenant';

import './TenantInfo.scss';
import { FunctionsHelper } from '../../utils/FunctionsHelper';

function TenantInfo() {
  const tenant: Tenant = useSelector((state: any) => state.tenant);

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        padding: '20px 20px',
        gap: '20px',
      }}
    >
      <div
        style={{
          width: '400px',
          display: 'flex',
          gap: '20px',
          flexDirection: 'column',
        }}
      >
        <h1
          style={{
            color: 'gray',
            fontWeight: '700',
            textAlign: 'center',
            textTransform: 'uppercase',
            fontSize: '20px',
          }}
        >
          Inquilino: {tenant.name}
        </h1>

        {tenant.tenantAppLogo && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <img
              src={`${FunctionsHelper.getS3FileUrl(tenant.tenantAppLogo)}`}
              alt="logo"
              style={{
                width: '100px',
              }}
            />
          </div>
        )}

        <pre
          style={{
            overflowX: 'auto',
            backgroundColor: '#f4f4f4',
            padding: '20px',
            border: '1px solid #ccc',
            borderRadius: '5px',
            maxHeight: '30vh',
            fontFamily: `'Courier New', Courier, monospace`,
            color: '#333',
            fontSize: '13px',
          }}
        >
          {JSON.stringify(tenant, null, 2)}
        </pre>

        <h3>Paleta de colores del inquilino</h3>

        <div>
          {tenant.privateCssProperties.map((property) => (
            <div
              style={{
                backgroundColor: property.value,
                color: 'black',
                height: '30px',
                width: '100%',
                marginBottom: '5px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              --{property.key}
            </div>
          ))}
        </div>

        <h3>Paleta de colores del genérica</h3>

        <div>
          {tenant.publicCssProperties.map((property) => (
            <div
              style={{
                backgroundColor: property.value,
                color: 'black',
                height: '30px',
                width: '100%',
                marginBottom: '5px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              --{property.key}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default TenantInfo;
