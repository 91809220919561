import { User } from "../../models/User";
import { Action } from "../models/Action";
import {
  SIGN_IN,
  SIGN_OUT,
  UPDATE_AUTH_INFO,
} from "../actions/authActions/ActionTypes";

const defaultStore: User = {
  id: 0,
  username: "",
  userType: "REGULAR_ACCOUNT",
  name: "",
  lastname: "",
  email: "",
  dialCode: "+56",
  phone: null,
  avatar: null,
  createdAt: new Date(),
  activatedAt: null,
  disabledAt: null,
  roles: [],
  permissions: [],
  token: null,
  allowedMenus: [],
};

export default function AuthReducer(
  state: User = defaultStore,
  action: Action
) {
  switch (action.type) {
    case SIGN_IN:
      return {
        id: action.payload.id,
        username: action.payload.username,
        userType: action.payload.userType,
        name: action.payload.name,
        lastname: action.payload.lastname,
        email: action.payload.email,
        dialCode: action.payload.dialCode,
        phone: action.payload.phone,
        avatar: action.payload.avatar,
        createdAt: action.payload.createdAt,
        activatedAt: action.payload.activatedAt,
        disabledAt: action.payload.disabledAt,
        roles: action.payload.roles,
        permissions: action.payload.permissions,
        token: action.payload.token,
        allowedMenus: action.payload.allowedMenus,
      };
    case UPDATE_AUTH_INFO:
      return {
        ...state,
        ...action.payload,
      };
    case SIGN_OUT:
      return {
        ...state,
        ...defaultStore,
      };
    default:
      return state;
  }
}
