import React, { useEffect, useState } from 'react';
import { useDispatch, useStore } from 'react-redux';
import setUpInterceptor from './utils/AxiosInterceptos';
import { AuthService } from './services/AuthService';
import LoadingScreen from './components/LoadingScreen/LoadingScreen';
import AuthAction from './redux/actions/authActions/Actions';
import AppRoutes from './routes/routes';
import { TenantService } from './services/TenantService';
import { Tenant } from './models/Tenant';
import { loadTenantConfig } from './config/TenantConfig';
import TenantAction from './redux/actions/tenantActions/Actions';
import TenantNotAvailable from './components/TenantNotAvailable/TenantNotAvailable';
import { User } from './models/User';

import './App.scss';

function App() {
  const store = useStore();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [tenantUploadError, setTenantUploadError] = useState(false);

  /* Iniciando los interceptores de axios para el token */
  setUpInterceptor(store);

  useEffect(() => {
    init();
  }, [dispatch, store]);

  const init = async () => {
    await verifyTenantAndUserSession();
    setLoading(false);
  };

  /* Rehidratación del usuario logeado y el tenant */
  const verifyTenantAndUserSession = async (): Promise<void> => {
    const [tenantResponse, verifyUserSessionResponse] = await Promise.all([
      TenantService.getTenantInfo(),
      AuthService.verifySession(),
    ]);
    console.log(tenantResponse);
    console.log(verifyUserSessionResponse);
    if (tenantResponse.success) {
      const tenant = tenantResponse.data as Tenant;
      dispatch(TenantAction.save(tenant));
      loadTenantConfig(tenant);
    } else {
      setTenantUploadError(true);
    }

    if (verifyUserSessionResponse.success) {
      dispatch(AuthAction.signIn(verifyUserSessionResponse.data as User));
    } else {
      dispatch(AuthAction.signOut({}));
    }
  };

  return (
    <>
      {loading ? (
        <LoadingScreen loading/>
      ) : tenantUploadError ? (
        <TenantNotAvailable />
      ) : (
        <AppRoutes />
      )}
    </>
  );
}

export default App;
