import MainBackendAxios from "../utils/MainBackendAxios";
import { Tenant } from "../models/Tenant";
import { ApiErrorData, ApiResponse } from "../models/ApiResponse";
import { FunctionsHelper } from "../utils/FunctionsHelper";

export const TenantService = {
  getTenantInfo: async (): Promise<ApiResponse<Tenant | ApiErrorData>> => {
    try {
      const response = await MainBackendAxios.get(`/tenant/get-current`);
      const tenantData = response?.data?.data;
      return {
        success: true,
        status: response.status,
        data: {
          id: tenantData.id,
          name: tenantData.name,
          domain: tenantData.domain,
          countryCode: tenantData.countryCode,
          currencyCode: tenantData.currencyCode,
          defaultDialCode: tenantData.defaultDialCode,
          mainTimezone: tenantData.mainTimezone,
          tenantAppTitle: tenantData.tenantAppTitle,
          tenantAppLogo: tenantData.tenantAppLogo,
          tenantAppFavicon: tenantData.tenantAppFavicon,
          currency: {
            code: tenantData.currency.code,
            name: tenantData.currency.name,
            symbol: tenantData.currency.symbol,
          },
          country: {
            code: tenantData.country.code,
            name: tenantData.country.name,
            alpha3Code: tenantData.country.alpha3Code,
            dialCode: tenantData.country.dialCode,
            mainTimezone: tenantData.country.mainTimezone,
          },
          publicCssProperties: tenantData.publicCssProperties
            ? tenantData.publicCssProperties.map((cssRawProperty: any) => {
                return {
                  key: cssRawProperty.key,
                  value: cssRawProperty.value,
                };
              })
            : [],
          privateCssProperties: tenantData.privateCssProperties
            ? tenantData.privateCssProperties.map((cssRawProperty: any) => {
                return {
                  key: cssRawProperty.key,
                  value: cssRawProperty.value,
                };
              })
            : [],
          availableMenus: tenantData.recruiterCrossAppMenus
            ? tenantData.recruiterCrossAppMenus.map(
                (recruiterCrossAppMenu: any) => {
                  return {
                    code: recruiterCrossAppMenu.code,
                    title: recruiterCrossAppMenu.title,
                    menuTypeId: recruiterCrossAppMenu.menuTypeId,
                    redirectLink: recruiterCrossAppMenu.redirectLink,
                    additionalConfig: recruiterCrossAppMenu.additionalConfig,
                    columnOrder: recruiterCrossAppMenu.columnOrder,
                    icon: recruiterCrossAppMenu.menuIcon
                      ? {
                          id: recruiterCrossAppMenu.menuIcon.id,
                          name: recruiterCrossAppMenu.menuIcon.name,
                          url: recruiterCrossAppMenu.menuIcon.url,
                        }
                      : null,
                  };
                }
              )
            : [],
        },
      };
    } catch (err) {
      return FunctionsHelper.getErrorFormat(err);
    }
  },
};
