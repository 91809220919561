export const MAIN_BACKEND_URL =
  process.env.REACT_APP_MAIN_BACKEND_URL ||
  "https://api-integrations.dev.titask.com/ms-activation/v1/kit-23";
export const APP_LANGUAGE = process.env.REACT_APP_LANGUAGE || "en";
export const ENV = process.env.NODE_ENV || "development";

export enum MenuType {
  STANDARD_MENU = "STANDARD_MENU",
  IFRAME_VIEWER = "IFRAME_VIEWER",
  SUBTITLE = "SUBTITLE",
  URL_REDIRECT = "URL_REDIRECT",
  BUTTON_REDIRECT = "BUTTON_REDIRECT",
}
