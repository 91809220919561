import { CircularProgress, colors } from "@mui/material";
import './LoadingScreen.scss';
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Tenant } from "../../models/Tenant";

interface ILoadingSpin {
  loading: boolean,
  loadingtext?: string
};

const LoadingScreen = ({ loading, loadingtext = '' }: ILoadingSpin): JSX.Element => {
  const tenant: Tenant = useSelector((state: any) => state.tenant);
  useEffect(() => {

    if (loading) {
      document.body.classList.add('stop-scrolling');
    }
    else {
      document.body.classList.remove('stop-scrolling');
    }

    return () => {
      document.body.classList.remove('stop-scrolling');
    };

  }, [loading]);

  return (
    <>
      {
        loading
        && (
          <div className="loading_spin__container">
            {tenant.privateCssProperties.map((property) => (
              property.key == 'tenant_color_primario' && <CircularProgress style={{ color: property.value }} />

            ))}
            {
              loadingtext && (
                <div className="mt-30 text__main" style={{ textAlign: 'center', maxWidth: '200px' }}>{loadingtext}</div>
              )
            }
          </div>
        )
      }
    </>
  );
};

export default LoadingScreen;