import { createStore, applyMiddleware, compose } from "redux";
import { createEpicMiddleware } from "redux-observable";

import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { createBrowserHistory } from "history";
import { createRouterMiddleware } from "@lagunovsky/redux-react-router";
import logger from "redux-logger";
import rootReducer from "./Reducers";

const epicMiddleware = createEpicMiddleware();

const composeEnhancers =
  typeof window === "object" &&
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      })
    : compose;

export const history = createBrowserHistory();

const enhancer = composeEnhancers(
  applyMiddleware(
    epicMiddleware,
    createRouterMiddleware(history) as any,
    logger as any
  )
);

const persistConfig = {
  key: "root_recruiters_cross_app",
  storage,
};

const persistedReducer = persistReducer(
  persistConfig,
  rootReducer(history) as any
);

const store = createStore(persistedReducer, enhancer);

export function makeStore() {
  const persistor = persistStore(store);

  return { store, persistor };
}

export type RootState = ReturnType<typeof store.getState>;
