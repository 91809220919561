import React from 'react';

import './Unauthorized.scss';

function Unauthorized() {
  return (
    <div>[403] Lo sentimos, no está autorizado para acceder a esta página.</div>
  );
}

export default Unauthorized;
